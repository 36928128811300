<template>
  <div class="pt-4 px-4 bg-white rounded-2 shadow-sm con w-100">
    <div class="table-container overflow-hidden">
      <table class="table m-0" aria-describedby="myskeleton">
        <thead class="border-bottom">
          <tr>
            <th scope="">
              <div
                style="width: 28px; height: 16px"
                class="rounded-2 skeleton"
              ></div>
            </th>
            <th scope="">
              <div
                style="width: 121px; height: 16px"
                class="rounded-2 skeleton"
              ></div>
            </th>
            <th scope="">
              <div
                style="width: 121px; height: 16px"
                class="rounded-2 skeleton"
              ></div>
            </th>
            <th scope="">
              <div
                style="width: 121px; height: 16px"
                class="rounded-2 skeleton"
              ></div>
            </th>
            <th scope="">
              <div
                style="width: 121px; height: 16px"
                class="rounded-2 skeleton"
              ></div>
            </th>
            <th scope="">
              <div
                style="width: 70px; height: 16px"
                class="rounded-2 skeleton"
              ></div>
            </th>
            <th scope="">
              <div
                style="width: 28px; height: 16px"
                class="rounded-2 skeleton"
              ></div>
            </th>
          </tr>
        </thead>
        <tbody class="border-0">
          <tr class="border-bottom" v-for="index in 13" :key="index">
            <th scope="">
              <div
                style="width: 28px; height: 16px"
                class="rounded-2 skeleton-item"
              ></div>
            </th>
            <td>
              <div
                style="width: 100%; height: 16px"
                class="rounded-2 skeleton-item"
              ></div>
            </td>
            <td>
              <div
                style="width: 100%; height: 16px"
                class="rounded-2 skeleton-item"
              ></div>
            </td>
            <td>
              <div
                style="width: 100%; height: 16px"
                class="rounded-2 skeleton-item"
              ></div>
            </td>
            <td>
              <div
                style="width: 100%; height: 16px"
                class="rounded-2 skeleton-item"
              ></div>
            </td>
            <td>
              <div
                style="width: 70px; height: 16px"
                class="rounded-2 skeleton-item"
              ></div>
            </td>
            <th scope="col">
              <div
                style="width: 28px; height: 16px"
                class="rounded-2 skeleton-item"
              ></div>
            </th>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      skeleton: [],
    };
  },
};
</script>

<style lang="scss" scoped>
.skeleton {
  background-color: $black-300;
  margin-top: 6px;
  position: relative;
  overflow: hidden;
  animation: skeleton 2s infinite ease-out;
  opacity: 1;
}
.skeleton-item {
  background-color: $black-300;
  margin-top: 6px;
  position: relative;
  overflow: hidden;
  animation: skeleton 2s infinite ease-out;
  opacity: 1;
}
@keyframes skeleton {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.4;
  }
  100% {
    opacity: 1;
  }
}
.table-container {
  &::-webkit-scrollbar {
    display: none;
  }
}
</style>